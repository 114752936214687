import { ReactNode, RefObject } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PaymentCategoryType } from '@models/payment-category';
import { IconBuild, IconChurch, IconWorld } from '@components/atoms/icons';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';

const messages = defineMessages({
  titleChurch: {
    id: 'lpGvMF',
    defaultMessage: 'Local Offerings',
    description: 'Title for local categories on envelope page.',
  },
  titleChurchParent: {
    id: 'PlllZM',
    defaultMessage: 'Conference/Union Offerings',
    description: 'Title for conference categories on envelope page.',
  },
  titleWorld: {
    id: 'duaEA3',
    defaultMessage: 'World Offerings',
    description: 'Title for world categories on envelope page.',
  },
});

type EnvelopeCategoryLineItemsMap = {
  [key in PaymentCategoryType]: {
    icon: ReactNode;
    title: string;
  };
};

interface Props {
  category: PaymentCategoryType;
  localOfferingRef?: RefObject<HTMLInputElement>;
  conferenceOfferingRef?: RefObject<HTMLInputElement>;
  worldOfferingRef?: RefObject<HTMLInputElement>;
}

const TitheEnvelopeTitle = ({
  category,
  localOfferingRef,
  conferenceOfferingRef,
  worldOfferingRef,
}: Props) => {
  const intl = useIntl();
  if (category === 'tithe') {
    return <></>;
  }

  const categoryLineItemMap: EnvelopeCategoryLineItemsMap = {
    tithe: {
      icon: '',
      title: '',
    },
    church: {
      title: intl.formatMessage(messages.titleChurch),
      icon: <IconChurch height={22} width={22} size="S" color="nad-blue" />,
    },
    'church.parent': {
      title: intl.formatMessage(messages.titleChurchParent),
      icon: <IconBuild height={22} width={22} size="S" color="nad-blue" />,
    },
    world: {
      title: intl.formatMessage(messages.titleWorld),
      icon: <IconWorld height={22} width={22} size="S" color="nad-blue" />,
    },
  };
  return (
    <div
      className="flex space-x-3 pt-3 lg:pt-0"
      ref={
        localOfferingRef && category === 'church'
          ? localOfferingRef
          : conferenceOfferingRef && category === 'church.parent'
          ? conferenceOfferingRef
          : worldOfferingRef && category === 'world'
          ? worldOfferingRef
          : undefined
      }
    >
      {categoryLineItemMap[category].icon}
      <HeadingUppercase variant="h5" as="h2" color="nad-blue">
        {categoryLineItemMap[category].title}
      </HeadingUppercase>
    </div>
  );
};

export default TitheEnvelopeTitle;
